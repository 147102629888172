*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: inherit;
  box-sizing: inherit; }

html {
  font-size: 62.5%; }
  @media only screen and (max-width: 75em) {
    html {
      font-size: 56.25%; } }
  @media only screen and (max-width: 56.25em) {
    html {
      font-size: 50%; } }
  @media only screen and (min-width: 112.5em) {
    html {
      font-size: 75%; } }

body {
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  line-height: 1.7;
  font-size: 1.6rem; }

h2 {
  text-transform: uppercase;
  text-align: center;
  font-size: 3rem;
  padding: 3rem 0;
  font-weight: bold; }
  h2 span {
    border-bottom: 4px solid #dfa923; }

h3 {
  text-transform: uppercase;
  font-size: 2rem;
  color: #919aa1; }

h4 {
  text-transform: uppercase;
  font-size: 3rem;
  color: #fff;
  font-weight: bold;
  letter-spacing: 0.5rem; }

/* TOP BANNER STYLING */
.top-banner img {
  height: 10rem;
  margin: 0.8rem 0; }

#topnavbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 9999; }

.contactnumber {
  font-weight: bold;
  font-size: 2rem;
  font-family: "Montserrat", sans-serif;
  padding-right: 4rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0; }

a[href^="tel"] {
  text-decoration: inherit;
  color: inherit; }

.navbar {
  padding: 0;
  background-color: #000; }
  .navbar-hidden-logo {
    opacity: 0;
    visibility: hidden; }
  .navbar-toggler {
    border: 1px solid red;
    outline: none; }
    .navbar-toggler .navbar-hamburger-icon {
      font-size: 2rem;
      color: #ced4da; }
  .navbar-nav .nav-link {
    padding: 1.5rem 2rem !important;
    color: #ced4da !important;
    text-transform: uppercase; }
    .navbar-nav .nav-link:hover {
      color: #fff !important;
      background-color: #1F9BCF; }
  .navbar.scrolled {
    background-color: rgba(0, 0, 0, 0.8); }
    .navbar.scrolled .navbar-hidden-logo {
      opacity: 1;
      visibility: visible;
      -webkit-transition: opacity 0.5s;
      transition: opacity 0.5s; }
  .navbar-hidden-logo {
    position: absolute;
    left: 3%; }
    .navbar-hidden-logo img {
      height: 4rem; }

.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  background: #1F9BCF;
  color: #fff !important; }

.section-header {
  height: 75vh; }
  .section-header .showcase {
    height: 100%;
    background: url(../img/showcase-2-small.jpg) no-repeat center center/cover;
    position: relative; }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em), (min-resolution: 192dpi) and (min-width: 37.5em), (min-width: 125em) {
      .section-header .showcase {
        background: url(../img/showcase-2.jpg) no-repeat center center/cover; } }
    .section-header .showcase::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(105deg, transparent 50%, rgba(31, 155, 207, 0.5) 50%);
      z-index: 100; }
      @media only screen and (max-width: 56.25em) {
        .section-header .showcase::before {
          background: -webkit-gradient(linear, left top, left bottom, from(rgba(31, 155, 207, 0.5)));
          background: linear-gradient(rgba(31, 155, 207, 0.5)); } }
    .section-header .showcase-content {
      position: absolute;
      top: 50%;
      right: 25%;
      width: 100%;
      -webkit-transform: translate(15%, -50%);
      transform: translate(15%, -50%);
      z-index: 101;
      margin-right: 1rem;
      text-align: right; }
      @media only screen and (max-width: 56.25em) {
        .section-header .showcase-content {
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          text-align: center; } }
      .section-header .showcase-content h1 {
        text-transform: uppercase;
        font-family: "Raleway", sans-serif;
        color: #fff;
        font-size: 5rem;
        margin-bottom: 0.5rem;
        font-weight: bold;
        letter-spacing: 1rem;
        -webkit-transform: translate(100rem, 0);
        transform: translate(100rem, 0);
        -webkit-animation: slideIn 1.5s forwards;
        animation: slideIn 1.5s forwards;
        -webkit-animation-delay: 0.5s;
        animation-delay: 0.5s; }
      .section-header .showcase-content p {
        color: #fff;
        font-size: 2rem;
        text-transform: uppercase;
        margin-bottom: 2rem;
        font-weight: bold;
        padding-right: 1rem;
        -webkit-transform: translate(100rem, 0);
        transform: translate(100rem, 0);
        -webkit-animation: slideIn 1.5s forwards;
        animation: slideIn 1.5s forwards;
        -webkit-animation-delay: 1.5s;
        animation-delay: 1.5s; }
      .section-header .showcase-content a {
        text-transform: uppercase;
        text-decoration: none;
        background: #dfa923;
        color: #fff;
        font-weight: bold;
        font-size: 2rem;
        padding: 1rem 2rem;
        border: 1px solid #dfa923;
        margin-right: 1rem;
        opacity: 0;
        -webkit-animation: fadeIn 1.5s forwards;
        animation: fadeIn 1.5s forwards;
        -webkit-animation-delay: 3s;
        animation-delay: 3s; }
        .section-header .showcase-content a:hover {
          background: #fff;
          color: #dfa923;
          border: 1px solid #dfa923; }

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: translate(100rem, 0);
    transform: translate(100rem, 0); }
  50% {
    -webkit-transform: translate(-5rem, 0);
    transform: translate(-5rem, 0); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

@keyframes slideIn {
  0% {
    -webkit-transform: translate(100rem, 0);
    transform: translate(100rem, 0); }
  50% {
    -webkit-transform: translate(-5rem, 0);
    transform: translate(-5rem, 0); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.section-about-us p {
  color: #9c6e4e; }

.section-supliers {
  height: 100%; }
  .section-supliers h2 {
    font-size: 2rem; }
  .section-supliers img {
    height: 8rem; }

.section-why_us {
  background-color: rgba(31, 155, 207, 0.1);
  height: 100%; }
  .section-why_us .container {
    height: 100%; }
    .section-why_us .container .card {
      opacity: 0;
      margin: 0.5rem;
      background-color: transparent;
      border: none;
      text-align: center;
      height: 100%; }
      .section-why_us .container .card img {
        height: 10rem;
        width: 10rem;
        margin: 1rem auto; }
      .section-why_us .container .card h3 {
        text-transform: uppercase;
        color: #dfa923;
        font-weight: bold;
        letter-spacing: 0.3rem; }
    .section-why_us .container .card-fade-in {
      -webkit-animation: slideInContact 1s forwards !important;
      animation: slideInContact 1s forwards !important;
      -webkit-animation-delay: 0.5s !important;
      animation-delay: 0.5s !important; }

@-webkit-keyframes slideInContact {
  0% {
    -webkit-transform: translate(0, 20rem);
    transform: translate(0, 20rem);
    opacity: 0; }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1; } }

@keyframes slideInContact {
  0% {
    -webkit-transform: translate(0, 20rem);
    transform: translate(0, 20rem);
    opacity: 0; }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1; } }

.section-our-services .services-boxes {
  width: 100%;
  height: 100%; }
  .section-our-services .services-boxes p {
    padding: 1.5rem; }
  .section-our-services .services-boxes-header {
    position: relative;
    border-right: 3px solid rgba(31, 155, 207, 0.2);
    border-bottom: 3px solid rgba(31, 155, 207, 0.2); }
    @media only screen and (max-width: 37.5em) {
      .section-our-services .services-boxes-header {
        padding-bottom: 3rem; } }
    .section-our-services .services-boxes-header h2 {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
  .section-our-services .services-boxes-first {
    border-bottom: 3px solid rgba(31, 155, 207, 0.2); }
  .section-our-services .services-boxes-second {
    border-right: 3px solid rgba(31, 155, 207, 0.2); }
  .section-our-services .services-boxes-third h2 {
    letter-spacing: 0.8rem; }
  @media only screen and (max-width: 37.5em) {
    .section-our-services .services-boxes {
      border: none; } }

.section-our-services .services-bottom {
  position: relative;
  background: url(../img/services-small.jpg) no-repeat center center/cover;
  background-attachment: fixed; }
  @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em), (min-resolution: 192dpi) and (min-width: 37.5em), (min-width: 125em) {
    .section-our-services .services-bottom {
      background: url(../img/services.jpg) no-repeat center center/cover; } }
  .section-our-services .services-bottom::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(223, 169, 35, 0.8); }
  .section-our-services .services-bottom ul {
    list-style: none;
    color: #f8f9fa; }
    .section-our-services .services-bottom ul .li-header {
      text-align: center;
      margin-bottom: 2rem;
      font-size: 2rem; }
    .section-our-services .services-bottom ul li {
      font-size: 1.7rem;
      text-transform: uppercase;
      font-weight: bold; }
      .section-our-services .services-bottom ul li i {
        color: #fff;
        margin-right: 1rem; }
      .section-our-services .services-bottom ul li:hover,
      .section-our-services .services-bottom ul li:hover i {
        color: #000; }

.galery {
  background-color: rgba(31, 155, 207, 0.1); }
  .galery .row-header {
    position: relative;
    height: 100%;
    border: 3px solid #dfa923; }
    @media only screen and (max-width: 37.5em) {
      .galery .row-header {
        height: 5rem; } }
    .galery .row-header h3 {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      color: #000;
      font-size: 2.5rem; }
  .galery .row-boxes {
    height: 15rem;
    max-width: 100%;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    -webkit-transition: 0.5s;
    transition: 0.5s; }
    @media only screen and (max-width: 37.5em) {
      .galery .row-boxes {
        max-width: 40rem;
        height: 30rem;
        margin: 0 auto; } }
    .galery .row-boxes:hover {
      -webkit-filter: grayscale(0);
      filter: grayscale(0);
      -webkit-transform: scale(1.1);
      transform: scale(1.1); }
    .galery .row-boxes .row-boxes-img {
      width: 100%;
      height: 100%; }
      .galery .row-boxes .row-boxes-img-1 {
        background: url(../img/gallery-2-small.jpg) no-repeat center center/cover; }
      .galery .row-boxes .row-boxes-img-2 {
        background: url(../img/gallery-6-small.jpg) no-repeat center center/cover; }
      .galery .row-boxes .row-boxes-img-3 {
        background: url(../img/gallery-5-small.jpg) no-repeat center center/cover; }
      .galery .row-boxes .row-boxes-img-4 {
        background: url(../img/gallery-1-small.jpg) no-repeat center center/cover; }
      .galery .row-boxes .row-boxes-img-5 {
        background: url(../img/gallery-10.jpg) no-repeat center center/cover; }
      .galery .row-boxes .row-boxes-img-6 {
        background: url(../img/gallery-4-small.jpg) no-repeat center center/cover; }
      .galery .row-boxes .row-boxes-img-7 {
        background: url(../img/gallery-7-small.jpg) no-repeat center center/cover; }
      .galery .row-boxes .row-boxes-img-8 {
        background: url(../img/gallery-8-small.jpg) no-repeat center center/cover; }
      .galery .row-boxes .row-boxes-img-9 {
        background: url(../img/gallery-9-small.jpg) no-repeat center center/cover; }
      .galery .row-boxes .row-boxes-img-10 {
        background: url(../img/gallery-3-small.jpg) no-repeat center center/cover; }

.section-contact {
  background: url(../img/contact-small.jpg) no-repeat center center/cover;
  padding-bottom: 4rem; }
  @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em), (min-resolution: 192dpi) and (min-width: 37.5em), (min-width: 125em) {
    .section-contact {
      background: url(../img/contact.jpg) no-repeat center center/cover; } }
  .section-contact .contact-box {
    width: 100%;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(60%, transparent), color-stop(60%, rgba(31, 155, 207, 0.95)));
    background: linear-gradient(to bottom, transparent 60%, rgba(31, 155, 207, 0.95) 60%);
    -webkit-box-shadow: 0.2rem 0.2rem 2rem rgba(0, 0, 0, 0.2);
    box-shadow: 0.2rem 0.2rem 2rem rgba(0, 0, 0, 0.2); }
    .section-contact .contact-box .details-box {
      height: 100%; }
      @media only screen and (max-width: 37.5em) {
        .section-contact .contact-box .details-box {
          padding-bottom: 1rem; } }
      .section-contact .contact-box .details-box img {
        height: 2rem;
        margin-right: 1.5rem; }
    .section-contact .contact-box .form-box {
      width: 100%;
      background: white;
      -webkit-box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.2);
      opacity: 0; }
      .section-contact .contact-box .form-box-inner {
        padding: 3rem; }
        .section-contact .contact-box .form-box-inner .hidden {
          display: none; }
        .section-contact .contact-box .form-box-inner h3 {
          padding-bottom: 1rem; }
        .section-contact .contact-box .form-box-inner .form-input {
          font-size: 1.5rem;
          border-bottom: 3px solid transparent; }
          .section-contact .contact-box .form-box-inner .form-input:hover {
            border-bottom: 3px solid #1f9bcf; }
    .section-contact .contact-box .card-fade-in {
      -webkit-animation: slideInContact 1s forwards !important;
      animation: slideInContact 1s forwards !important;
      -webkit-animation-delay: 0.5s !important;
      animation-delay: 0.5s !important; }
  @media only screen and (max-width: 37.5em) {
    .section-contact .contact-footer {
      padding-top: 1rem; } }
  .section-contact .contact-footer p {
    color: #fff;
    padding-bottom: 1rem; }

.footer {
  background-color: black;
  color: white; }
  .footer-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 4rem 0; }
    .footer-content a {
      color: #adb5bd; }
    @media only screen and (max-width: 37.5em) {
      .footer-content {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 1rem; } }
  @media only screen and (max-width: 75em) {
    .footer {
      padding: 0 3rem; } }
